<template>
  <div class="data-widget">
    <div class="widget-label">{{label}}</div>
    <div class="widget-value">
      <el-cascader :options="areaSelectData" @change="changeRegion" :props="{ checkStrictly: true }" clearable class="el-input" v-model="defaultArea" placeholder="请选择您所在的城市">
      </el-cascader>
    </div>
    <div class="widget-value" v-if="extend.key">
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入详细小区，街道地址"
        v-model="inputValue"
      >
      </el-input>
    </div>
  </div>
</template>
<script>
import { regionData,codeToText } from 'element-china-area-data';
import MXTextarea from '@/pages/components/mx-ui-components/mx-textarea'

export default {
  name: 'MXRegion',
  components: {},
  props: {
    label: {
      type: String,
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
    extend: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    value: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      areaSelectData: regionData,
      defaultArea: [],
      inputValue: this.extend.value,
    }
  },
  methods: {
    changeRegion(data) {
      if(data.length > 0)
        data = data.map(d => {return codeToText[d]})

      this.$emit("changeRegion", {
        identifier: this.identifier,
        value: data
      })
    }
  },
  mounted() {
    // console.log("codeToText", codeToText)
    // console.log("regionData", regionData)
    // Object.keys(codeToText).forEach(key => {
    //   this.textToCode[codeToText[key]] = key + ''
    // })
    console.log('[this.extend.key]', this.extend)
  },
  watch: {
    'inputValue': {
      handler: function(val) {
        this.$emit('input', {
          identifier: this.extend.key,
          value: val
        })
      }
    },
    'value': {
      handler: function(val) {
        let regions = val
        let regionCodes = []
        let regionObj   = regionData

        if(regions.length > 0) {
          // this.defaultArea = regions.map(r => {
          //   return this.textToCode[r]})

          for(let index = 0; index < regions.length; index++) {
            let _region = regionObj.find(_r => {return _r.label == regions[index]})
            regionCodes.push(_region.value)
            regionObj =  _region.children
          }

          this.defaultArea = regionCodes
        }
      },
      immediate: true,
    }
  }
}

</script>
<style lang="scss" scoped>
.widget-label {
  padding-bottom: 4px;
  color: #525967;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  word-wrap: break-word;
  word-break: break-word;
}

.widget-value {
  min-height: 32px;
  padding: 6px 0px;
  color: #141e31;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}

</style>
