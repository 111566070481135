<template>
    <div class="preview-wrapper">
        <div class="box-img">
            <div class="preview-widget-label" v-if="title && title.length">{{title}}</div>
            <div class="image-wrapper flex flex-wrap">
                <div class="block" v-for="url in images" v-if="images && images.length">
                    <div style="cursor:pointer;">
                        <el-image @click="onPreview(url)" style="width: 100px; height: 100px" :src="url" fit="cover"></el-image>
                    </div>
                </div>

                <div class="preview-widget-value" v-if="images.length == 0">
                    无
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MXPreview',
    components: {

    },
    props: {
        images: {
            type: Array,
        },
        title:{
            type:String
        }
    },
    data() {
        return {
        }
    },
    mounted() {},
    methods: {

    },
    watch: {

    }

}
</script>
<style lang="scss" scoped>
.block {
    margin-right: 10px;
}

.preview-widget-label {
  padding-bottom: 4px;
  color: #525967;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  word-wrap: break-word;
  word-break: break-word;
}

.preview-widget-value {
  color: #C0C4CC;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}

</style>