<template>
  <div class="data-widget">
    <div class="widget-label">{{label}}<span v-if="required" style="color:red; line-height: 22px;">*</span></div>
    <div class="widget-value">
      <el-date-picker v-model="selectValue" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions" format="yyyy-MM-dd">
      </el-date-picker>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MXDate',
  components: {},
  props: ['label', 'value', 'identifier', 'options','required'],
  data() {
    return {
      selectValue: '',
    }
  },
  computed: {
    pickerOptions() {
      return this.options ? this.options : {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }],
      }
    }
  },
  methods: {},
  watch: {
    'selectValue': {
      handler: function(val) {
        if (val && val == this.value) return
        this.$emit('select', {
          identifier: this.identifier,
          value: val
        })
      }
    },
    'value': {
      handler: function(val) {
        this.selectValue = val
      },
      immediate: true,
    }
  }


}

</script>
<style lang="scss" scoped>
:deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}

.data-widegt {}

.widget-label {
  padding-bottom: 4px;
  color: #525967;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #525967;
  word-wrap: break-word;
  word-break: break-word;
}

.widget-value {
  min-height: 32px;
  padding: 6px 0px;
  color: #141e31;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  background: none !important;
  border-radius: 2px;
  word-wrap: break-word;
  word-break: break-word;
}

</style>
