<template>
  <div>
    <div class="widget-label">{{label}}<span v-if="required" style="color:red; line-height: 22px;">*</span></div>
    <div class="widget-value">
      <div class="widget-text-value" v-if="disabled">
        {{selectLabel}}
      </div>
      <el-select v-if="!disabled" ref="selectRef" multiple v-model="selectLabel" clearable placeholder="请选择" @change='change'>
        <!-- <el-option value="1" hidden></el-option> -->
        <el-option :label="item.label" :value="item" v-for="(item,index) in dropdownList"></el-option>
        <!-- <div class="dropdown-wrapper">
          <el-input v-model="inputValue" prefix-icon="el-icon-search" placeholder="搜索" clearable />
          <div class="inner-wrapper">
            <div class="dropdown-list">
              <div :class="selectLabel == item.label?'dropdown-item-active':'dropdown-item'" v-for="(item,index) in dropdownList" @click="onSelectItem" :data-index="index">{{item.label}}</div>
            </div>
            <div class="dropdown-list-empty" v-if="dropdownList.length <= 0">
              暂无内容
            </div>
          </div>
        </div> -->
<!-- 
         <el-option :value="item.value" hidden v-for="(item,index) in options"></el-option>
        <div class="dropdown-wrapper">
          <el-input v-model="inputValue" prefix-icon="el-icon-search" placeholder="搜索" clearable />
          <div class="inner-wrapper">
            <div class="dropdown-list">
              <div :class="selectLabel == item.label?'dropdown-item-active':'dropdown-item'" v-for="(item,index) in dropdownList" @click="onSelectItem" :data-index="index">{{item.label}}</div>
            </div>
            <div class="dropdown-list-empty" v-if="dropdownList.length <= 0">
              暂无内容
            </div>
          </div>
        </div> -->
      </el-select>
    </div>
  </div>
</template>
<script>
// import request from '@/utils/request'
import http from '@/common/utils/http'
export default {
  name: 'MXSelect',
  components: {},
  props: ['label', 'value', 'api', 'identifier', 'dataKey', 'dataLabel', 'dataValue', 'disabled', 'required'],
  data() {
    return {
      options: [],
      dropdownList: [],
      selectLabel: [],
      inputValue: '',
      currentApi: '', //当前接口地址 为刷新使用
    }
  },
  mounted() {
    console.log(3333);
    if (this.api && this.api.length)
      this.getDataByApi(this.api)
  },
  methods: {
    change(){
      console.log(this.selectLabel,'this.selectLabel');
       this.$emit('onSelectMultiple', {
         identifier: this.identifier,
         data:this.selectLabel
       })
    },
    getDataByApi(api) {
      this.currentApi = api
      let _url = process.env.VUE_APP_BASE_API + "/" + api
      request({
        url: _url,
        method: 'GET',
        params: {}
      }).then(response => {
        this.options = this.parseListWithResponse(response)
        this.dropdownList = JSON.parse(JSON.stringify(this.options))
        console.log(this.dropdownList,'this.dropdownList');
        this.updateSelectLabelIfNeeded()
      }).catch(error => {
        this.options = []
        this.dropdownList = this.options

      })
    },
    reloadDataByApi(api) {
      this.selectLabel = []
      this.$nextTick(() => {
        this.currentApi = api
        this.getDataByApi(api)
      })
    },
    parseListWithResponse(response) {
      console.log(response,'response');
      // debugger
      let datakeys = this.dataKey.split('.')
      if (datakeys.length) {
        let target = response
        datakeys.forEach((key) => {
          target = target[key]
        })
        if (this.dataLabel && this.dataValue) {
          let array = target.map((item) => {
            return {
              label: item[this.dataLabel],
              value: item[this.dataValue],
              phoneNumber: item.mobile,
              id:item.id
            }
          })
          return array
        } else {
          return target
        }
      } else {
        return response.data.list
      }
      console.log(response,'responseresponseresponse');
    },
    updateSelectLabelIfNeeded() {
      if (this.options.length && (this.value || this.value === '0' || this.value === 0)) {
        let find = this.options.find((item) => {
          return item.value == this.value
        })
        this.selectLabel = find ? find.label : ''
      } else {
        this.selectLabel = ''
      }
    },
    onSelectItem(e) {
      let idx = e.currentTarget.dataset.index
      let item = this.dropdownList[idx]
      this.selectLabel = item.label
      this.$refs.selectRef.blur()

      let find = this.options.find((item) => {
        return item.label == this.selectLabel
      })
      this.$emit('select', {
        identifier: this.identifier,
        value: find ? find.value : '',
        label:find ? find.label : '',
      })
    }
  },
  watch: {
    'inputValue': {
      handler: function(val) {
        this.dropdownList = this.options.filter((item) => {
          return item.label.indexOf(val) > -1
        })
      },
    },
    'selectLabel': {
      handler: function(val) {
        // let find = this.options.find((item) => {
        //   return item.label == val
        // })
        // this.$emit('select', {
        //   identifier: this.identifier,
        //   value: find ? find.value : ''
        // })
      },
    },
    'value': {
      handler: function(val) {
        console.log(123);
        // this.updateSelectLabelIfNeeded()
      },
    }

  }
}

</script>
<style lang="scss" scoped>
:deep .el-select {
  width: 100%;
}

:deep.dropdown-wrapper {
  .el-input__inner {
    border: none;
    border-bottom: solid 1px #DFE4ED;
  }
}

.dropdown-item {
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  border-radius: 3px;
  -webkit-transition: all .2s;
  transition: all .2s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}

.dropdown-item-active {
  background: #1890ff;
  color: #fff;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  border-radius: 3px;
  -webkit-transition: all .2s;
  transition: all .2s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}

.dropdown-item-active:hover {
  background: #1890ff;
}

.dropdown-item:hover {
  background: #f0f1f4;
}

.dropdown-list {
  padding: 10px 5px 5px 5px;
}

.dropdown-list-empty {
  padding: 40px 0;
  text-align: center;
}

.widget-value {
  margin-top:5px;
  min-height:36px;
  line-height:36px;
  padding:0!important;
}

.widget-text-value {
  line-height:36px;
  padding:0 8px!important;
}

</style>
