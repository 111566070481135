//import { parseTime } from "@/utils/ruoyi";
//import { getDictDatas, DICT_TYPE } from '@/utils/dict'
export function datasInstance() {
  let tableColums = [{
      key: 'orderCode',
      label: '维修单号',
      width: 200,
      fixed: true,
      default: true,
      typeUi: 'el-input',
    },
    {
      key: 'elevatorCode',
      label: '电梯编号',
      default: true,
      typeUi: 'el-input',
    },
    // {
    //   key: 'elevatorName',
    //   label: '电梯名称',
    //   default: true,
    //   typeUi: 'el-input',
    // },
    // {
    //   key: 'villdageName',
    //   label: '所属小区',
    // },
    {
      key: 'installAddress',
      label: '地址',
    },
    // {
    //   key: 'leader',
    //   label: '负责人',
    // },
    {
      key: 'orderStatus',
      label: '工单状态',
      type: "customRender",
      render: (h, row, column) => {
        let val = '--'
        switch (row.orderStatus) {
          case 0:
            val = '待办'
            break
          case 1:
            val = '进行中'
            break
          case 2:
            val = '已完成'
            break
          case 3:
            val = '已确认'
            break
        }
        return <el-tag>{val}</el-tag>
      },
    },
    {
      key: 'reportTime',
      label: '报修日期',
      type: 'datetime',
    },
    {
      key: 'action',
      label: '操作',
      align:'center',
      type: "action",
      fixed: 'right',
      width: 300,
      render: (h, row, column) => {
        return <div style="display:flex; justify-content:center;">
          <el-button 
        onClick={() => {

          }
        }
          type="text"
          size="small">
            查看
          </el-button>
        </div>
      },
    },
  ]

  function parseDetailResponse(target, response) {
    let array = []
    target.forEach((item) => {
      item.value = handleItemValue(item, response)
      item.value = handleBizItemValue(item, response)
      array.push(item)
    })
    return array
  }

  function handleItemValue(item, data) {
    let value = data[item.key]
    switch (item.type) {
      case 'dict':
        break
      case 'datetime':
        value = parseTime(data[item.key])
        break
      case 'selectArea':
        let keys = item['composeKey']
        let _v = []
        keys.forEach((item) => {
          if (Array.isArray(item)) {
            _v.push(item.map(subItem => data[subItem]))
          } else {
            _v.push(data[item])
          }
        })
        value = _v

        if (item['extend']) {
          item['extend']['value'] = data[item['extend']['key']]
        }

        break
      case 'uploadFile':
        value = data[item.key]
      default:
        break
    }
    return value
  }

  function handleBizItemValue(item, data) {
    let value = item.value
    switch (item.key) {
      case 'region':
        value = data.province + data.city + data.district
        break
    }
    return value
  }

  return {
    getTableColumns() {
      return tableColums
    },
    parseDetailResponse(target, response) {
      return parseDetailResponse(target, response)
    }
  }
}
