
/**
 * 获取故障现象 1 直梯 2 扶梯 3 步道
 */ 
export function getFaultPhenomenon(type) {
    if (type == 1) {
        return [{
                "sort": 44,
                "label": "其他-其他的非设备故障",
                "value": "44",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1355,
                "createTime": 1700027649000
            },
            {
                "sort": 43,
                "label": "其他-其他的设备故障",
                "value": "43",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1354,
                "createTime": 1700027639000
            },
            {
                "sort": 42,
                "label": "安全保护装置-其他的非设备故障",
                "value": "42",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1353,
                "createTime": 1700027629000
            },
            {
                "sort": 41,
                "label": "安全保护装置-其他的设备故障",
                "value": "41",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1352,
                "createTime": 1700027616000
            },
            {
                "sort": 40,
                "label": "安全保护装置-紧急报警、应急照明故障",
                "value": "40",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1351,
                "createTime": 1700027607000
            },
            {
                "sort": 39,
                "label": "安全保护装置-轿厢意外移动保护装置动作",
                "value": "39",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1350,
                "createTime": 1700027595000
            },
            {
                "sort": 38,
                "label": "安全保护装置-轿厢上行超速保护装置动作",
                "value": "38",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1349,
                "createTime": 1700027583000
            },
            {
                "sort": 37,
                "label": "安全保护装置-张紧轮开关动作",
                "value": "37",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1348,
                "createTime": 1700027572000
            },
            {
                "sort": 36,
                "label": "安全保护装置-缓冲器开关动作",
                "value": "36",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1347,
                "createTime": 1700027562000
            },
            {
                "sort": 35,
                "label": "安全保护装置-安全钳开关动作",
                "value": "35",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1346,
                "createTime": 1700027549000
            },
            {
                "sort": 34,
                "label": "安全保护装置-限速器开关动作",
                "value": "34",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1345,
                "createTime": 1700027539000
            },
            {
                "sort": 33,
                "label": "控制系统-其他的非设备故障",
                "value": "33",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1344,
                "createTime": 1700027526000
            },
            {
                "sort": 32,
                "label": "控制系统-其他的设备故障",
                "value": "32",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1343,
                "createTime": 1700027513000
            },
            {
                "sort": 31,
                "label": "控制系统-轿内指令和层站呼梯按钮故障",
                "value": "31",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1342,
                "createTime": 1700027475000
            },
            {
                "sort": 30,
                "label": "控制系统-驱动系统（变频器）故障",
                "value": "30",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1341,
                "createTime": 1700027431000
            },
            {
                "sort": 29,
                "label": "控制系统-编码器故障",
                "value": "29",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1340,
                "createTime": 1700027412000
            },
            {
                "sort": 28,
                "label": "控制系统-电气连接不可靠",
                "value": "28",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1339,
                "createTime": 1700027364000
            },
            {
                "sort": 27,
                "label": "控制系统-极限开关、停止装置、检修运行控制装置等故障",
                "value": "27",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1338,
                "createTime": 1700027348000
            },
            {
                "sort": 26,
                "label": "控制系统-平层感应器故障",
                "value": "26",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1337,
                "createTime": 1700027331000
            },
            {
                "sort": 25,
                "label": "控制系统-电气元器件（如主开关、接触器、继电器、制动单元等）故障",
                "value": "25",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1336,
                "createTime": 1700027320000
            },
            {
                "sort": 24,
                "label": "控制系统-控制主板故障",
                "value": "24",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1335,
                "createTime": 1700027306000
            },
            {
                "sort": 23,
                "label": "控制系统-机器空间环境温度过高，电气控制系统自动保护",
                "value": "23",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1334,
                "createTime": 1700027294000
            },
            {
                "sort": 22,
                "label": "控制系统-控制线路短路故障（如进水、鼠咬、受潮、绝缘不良等）",
                "value": "22",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1333,
                "createTime": 1700027281000
            },
            {
                "sort": 21,
                "label": "控制系统-停电",
                "value": "21",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1332,
                "createTime": 1700027270000
            },
            {
                "sort": 20,
                "label": "曳引系统-其他的非设备故障",
                "value": "20",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1331,
                "createTime": 1700027252000
            },
            {
                "sort": 19,
                "label": "曳引系统-其他的设备故障",
                "value": "19",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1330,
                "createTime": 1700021161000
            },
            {
                "sort": 18,
                "label": "曳引系统-制动器故障",
                "value": "18",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1329,
                "createTime": 1700021126000
            },
            {
                "sort": 17,
                "label": "曳引系统-减速箱故障",
                "value": "17",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1328,
                "createTime": 1700021117000
            },
            {
                "sort": 16,
                "label": "曳引系统-悬挂钢丝绳或包覆绳（带）打滑或跳槽",
                "value": "16",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1327,
                "createTime": 1700021101000
            },
            {
                "sort": 15,
                "label": "曳引系统-曳引轮或轮槽（面）磨损严重、轴承异常",
                "value": "15",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1326,
                "createTime": 1700021091000
            },
            {
                "sort": 14,
                "label": "曳引系统-平衡系数不在标准范围内",
                "value": "14",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1325,
                "createTime": 1700021079000
            },
            {
                "sort": 13,
                "label": "门系统-其他的非设备故障",
                "value": "13",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1324,
                "createTime": 1700021066000
            },
            {
                "sort": 12,
                "label": "门系统-其他的设备故障",
                "value": "12",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1323,
                "createTime": 1700021055000
            },
            {
                "sort": 11,
                "label": "门系统-层门关闭保持装置（如重锤、弹簧等）故障",
                "value": "11",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1322,
                "createTime": 1700021042000
            },
            {
                "sort": 10,
                "label": "门系统-门导向系统（如门挂轮、门导靴等）故障",
                "value": "10",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1321,
                "createTime": 1700021030000
            },
            {
                "sort": 9,
                "label": "门系统-门强迫关闭（长时间开门后的低速强迫关门）功能异常",
                "value": "9",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1320,
                "createTime": 1700021017000
            },
            {
                "sort": 8,
                "label": "门系统-门保护装置（如光幕、安全触板等）故障",
                "value": "8",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1319,
                "createTime": 1700021007000
            },
            {
                "sort": 7,
                "label": "门系统-门刀与滚轮磕碰或间距超差",
                "value": "7",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1318,
                "createTime": 1700020998000
            },
            {
                "sort": 6,
                "label": "门系统-门机（门电动机、传动机构、驱动与控制系统等）故障",
                "value": "6",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1317,
                "createTime": 1700020957000
            },
            {
                "sort": 5,
                "label": "门系统-层门锁紧装置（机械或电气装置）故障",
                "value": "5",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1316,
                "createTime": 1700020948000
            },
            {
                "sort": 4,
                "label": "门系统-轿门锁紧装置（机械或电气装置）故障",
                "value": "4",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1315,
                "createTime": 1700020933000
            },
            {
                "sort": 3,
                "label": "门系统-阻挡关门时间过长，使电梯停止运行",
                "value": "3",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1314,
                "createTime": 1700020923000
            },
            {
                "sort": 2,
                "label": "门系统-人的鲁莽行为（扒门、踹门等），使电梯停止运行",
                "value": "2",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1313,
                "createTime": 1700020899000
            },
            {
                "sort": 1,
                "label": "门系统-异物卡阻导致开关门受阻，使电梯停止运行",
                "value": "1",
                "dictType": "elevator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1312,
                "createTime": 1700020885000
            }
        ]
    } else if (type == 2) {
        return [{
                "sort": 30,
                "label": "其他-其他的非设备故障",
                "value": "30",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1385,
                "createTime": 1700027967000
            },
            {
                "sort": 29,
                "label": "其他-其他的设备故障",
                "value": "29",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1384,
                "createTime": 1700027955000
            },
            {
                "sort": 28,
                "label": "安全保护装置-其他的非设备故障",
                "value": "28",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1383,
                "createTime": 1700027945000
            },
            {
                "sort": 27,
                "label": "安全保护装置-其他的设备故障",
                "value": "27",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1382,
                "createTime": 1700027935000
            },
            {
                "sort": 26,
                "label": "安全保护装置-制动器松闸故障",
                "value": "26",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1381,
                "createTime": 1700027922000
            },
            {
                "sort": 25,
                "label": "安全保护装置-检修盖板开关动作",
                "value": "25",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1380,
                "createTime": 1700027912000
            },
            {
                "sort": 24,
                "label": "安全保护装置-梯级链张紧开关动作",
                "value": "24",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1379,
                "createTime": 1700027900000
            },
            {
                "sort": 23,
                "label": "安全保护装置-扶手带速度偏离",
                "value": "23",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1378,
                "createTime": 1700027888000
            },
            {
                "sort": 22,
                "label": "安全保护装置-梯级或踏板缺失",
                "value": "22",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1377,
                "createTime": 1700027880000
            },
            {
                "sort": 21,
                "label": "安全保护装置-梯级或踏板下陷",
                "value": "21",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1376,
                "createTime": 1700027872000
            },
            {
                "sort": 20,
                "label": "安全保护装置-附加制动器未动作",
                "value": "20",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1375,
                "createTime": 1700027862000
            },
            {
                "sort": 19,
                "label": "安全保护装置-非操纵逆转保护",
                "value": "19",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1374,
                "createTime": 1700027851000
            },
            {
                "sort": 18,
                "label": "安全保护装置-超速保护",
                "value": "18",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1373,
                "createTime": 1700027841000
            },
            {
                "sort": 17,
                "label": "安全保护装置-手动操作紧急停止开关触发",
                "value": "17",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1372,
                "createTime": 1700027831000
            },
            {
                "sort": 16,
                "label": "安全保护装置-扶手带入口异物卡入导致停止运行",
                "value": "16",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1371,
                "createTime": 1700027822000
            },
            {
                "sort": 15,
                "label": "安全保护装置-梳齿板异物卡入导致停止运行",
                "value": "15",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1370,
                "createTime": 1700027813000
            },
            {
                "sort": 14,
                "label": "控制系统-其他的非设备故障",
                "value": "14",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1369,
                "createTime": 1700027805000
            },
            {
                "sort": 13,
                "label": "控制系统-其他的设备故障",
                "value": "13",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1368,
                "createTime": 1700027797000
            },
            {
                "sort": 12,
                "label": "控制系统-驱动系统（变频器）故障",
                "value": "12",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1367,
                "createTime": 1700027789000
            },
            {
                "sort": 11,
                "label": "控制系统-电气连接不可靠",
                "value": "11",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1366,
                "createTime": 1700027782000
            },
            {
                "sort": 10,
                "label": "控制系统-停止装置、检修控制装置等故障",
                "value": "10",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1365,
                "createTime": 1700027774000
            },
            {
                "sort": 9,
                "label": "控制系统-电气元器件（如主开关、接触器、继电器、制动单元等）故障",
                "value": "9",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1364,
                "createTime": 1700027765000
            },
            {
                "sort": 8,
                "label": "控制系统-控制主板故障",
                "value": "8",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1363,
                "createTime": 1700027752000
            },
            {
                "sort": 7,
                "label": "控制系统-机房（驱动站或转向站）环境温度过高，电气控制系统自动保护",
                "value": "7",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1362,
                "createTime": 1700027744000
            },
            {
                "sort": 6,
                "label": "控制系统-控制线路短路、接地故障（如进水、鼠咬、受潮、绝缘不良等）",
                "value": "6",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1361,
                "createTime": 1700027736000
            },
            {
                "sort": 5,
                "label": "控制系统-停电",
                "value": "5",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1360,
                "createTime": 1700027728000
            },
            {
                "sort": 4,
                "label": "驱动主机-其他的非设备故障",
                "value": "4",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1359,
                "createTime": 1700027720000
            },
            {
                "sort": 3,
                "label": "驱动主机-其他的设备故障",
                "value": "3",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1358,
                "createTime": 1700027712000
            },
            {
                "sort": 2,
                "label": "驱动主机-制动系统故障（启动后制动系统未释放）",
                "value": "2",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1357,
                "createTime": 1700027705000
            },
            {
                "sort": 1,
                "label": "驱动主机-减速箱故障",
                "value": "1",
                "dictType": "escalator_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1356,
                "createTime": 1700027691000
            }
        ]
    } else if (type == 3) {
        return [{
                "sort": 30,
                "label": "其他-其他的非设备故障",
                "value": "30",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1415,
                "createTime": 1700028292000
            },
            {
                "sort": 29,
                "label": "其他-其他的设备故障",
                "value": "29",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1414,
                "createTime": 1700028282000
            },
            {
                "sort": 28,
                "label": "安全保护装置-其他的非设备故障",
                "value": "28",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1413,
                "createTime": 1700028274000
            },
            {
                "sort": 27,
                "label": "安全保护装置-其他的设备故障",
                "value": "27",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1412,
                "createTime": 1700028265000
            },
            {
                "sort": 26,
                "label": "安全保护装置-制动器松闸故障",
                "value": "26",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1411,
                "createTime": 1700028257000
            },
            {
                "sort": 25,
                "label": "安全保护装置-检修盖板开关动作",
                "value": "25",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1410,
                "createTime": 1700028248000
            },
            {
                "sort": 24,
                "label": "安全保护装置-梯级链张紧开关动作",
                "value": "24",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1409,
                "createTime": 1700028239000
            },
            {
                "sort": 23,
                "label": "安全保护装置-扶手带速度偏离",
                "value": "23",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1408,
                "createTime": 1700028233000
            },
            {
                "sort": 22,
                "label": "安全保护装置-梯级或踏板缺失",
                "value": "22",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1407,
                "createTime": 1700028226000
            },
            {
                "sort": 21,
                "label": "安全保护装置-梯级或踏板下陷",
                "value": "21",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1406,
                "createTime": 1700028219000
            },
            {
                "sort": 20,
                "label": "安全保护装置-附加制动器未动作",
                "value": "20",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1405,
                "createTime": 1700028211000
            },
            {
                "sort": 19,
                "label": "安全保护装置-非操纵逆转保护",
                "value": "19",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1404,
                "createTime": 1700028200000
            },
            {
                "sort": 18,
                "label": "安全保护装置-超速保护",
                "value": "18",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1403,
                "createTime": 1700028190000
            },
            {
                "sort": 17,
                "label": "安全保护装置-手动操作紧急停止开关触发",
                "value": "17",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1402,
                "createTime": 1700028181000
            },
            {
                "sort": 16,
                "label": "安全保护装置-扶手带入口异物卡入导致停止运行",
                "value": "16",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1401,
                "createTime": 1700028172000
            },
            {
                "sort": 15,
                "label": "安全保护装置-梳齿板异物卡入导致停止运行",
                "value": "15",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1400,
                "createTime": 1700028163000
            },
            {
                "sort": 14,
                "label": "控制系统-其他的非设备故障",
                "value": "14",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1399,
                "createTime": 1700028154000
            },
            {
                "sort": 13,
                "label": "控制系统-其他的设备故障",
                "value": "13",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1398,
                "createTime": 1700028147000
            },
            {
                "sort": 12,
                "label": "控制系统-驱动系统（变频器）故障",
                "value": "12",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1397,
                "createTime": 1700028139000
            },
            {
                "sort": 11,
                "label": "控制系统-电气连接不可靠",
                "value": "11",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1396,
                "createTime": 1700028132000
            },
            {
                "sort": 10,
                "label": "控制系统-停止装置、检修控制装置等故障",
                "value": "10",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1395,
                "createTime": 1700028125000
            },
            {
                "sort": 9,
                "label": "控制系统-电气元器件（如主开关、接触器、继电器、制动单元等）故障",
                "value": "9",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1394,
                "createTime": 1700028116000
            },
            {
                "sort": 8,
                "label": "控制系统-控制主板故障",
                "value": "8",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1393,
                "createTime": 1700028108000
            },
            {
                "sort": 7,
                "label": "控制系统-机房（驱动站或转向站）环境温度过高，电气控制系统自动保护",
                "value": "7",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1392,
                "createTime": 1700028100000
            },
            {
                "sort": 6,
                "label": "控制系统-控制线路短路、接地故障（如进水、鼠咬、受潮、绝缘不良等）",
                "value": "6",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1391,
                "createTime": 1700028093000
            },
            {
                "sort": 5,
                "label": "控制系统-停电",
                "value": "5",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1390,
                "createTime": 1700028085000
            },
            {
                "sort": 4,
                "label": "驱动主机-其他的非设备故障",
                "value": "4",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1389,
                "createTime": 1700028034000
            },
            {
                "sort": 3,
                "label": "驱动主机-其他的设备故障",
                "value": "3",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1388,
                "createTime": 1700028027000
            },
            {
                "sort": 2,
                "label": "驱动主机-制动系统故障（启动后制动系统未释放）",
                "value": "2",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1387,
                "createTime": 1700028020000
            },
            {
                "sort": 1,
                "label": "驱动主机-减速箱故障",
                "value": "1",
                "dictType": "walkway_fault_phenomenon",
                "status": 0,
                "colorType": "default",
                "cssClass": "",
                "remark": null,
                "id": 1386,
                "createTime": 1700028011000
            }
        ]
    }
}