<template>
  <div>
    <el-form-item label="" :prop="item.key">
      <MXText :identifier="item.key" :label="item.label" :value="item.value" v-if="item.ui == 'mx-text'" />
      <MXInput ref="formItem" :required="item.required" :disabled="item.disabled" :identifier="item.key" @input="onInput" :label="item.label" :value="item.value" v-if="item.ui == 'mx-input'" />
      <MXTextArea :disabled="item.disabled" :identifier="item.key" @input="onInput" :label="item.label" :value="item.value" v-if="item.ui == 'mx-textarea'" />
      <MXSelect ref="selectItem" :required="item.required" :identifier="item.key" @select="onSelect" :label="item.label" :value="item.value" :dataKey="item.dataKey" :dataLabel="item.dataLabel" :dataValue="item.dataValue" v-if="item.ui == 'mx-select'" :api="item.api" :optionsList="item.optionsList" />
      <MXSelectMultiple ref="selectItem" :required="item.required" :identifier="item.key" @onSelectMultiple="onSelectMultiple" :label="item.label" :value="item.value" :dataKey="item.dataKey" :dataLabel="item.dataLabel" :dataValue="item.dataValue" v-if="item.ui == 'mx-select-multiple'" :api="item.api" />
      <MXRegion :identifier="item.key" :label="item.label" :value="item.value" :extend="item.extend" @changeRegion="onSelectRegion" @input="onInput" v-if="item.ui == 'mx-region'" />
      <MXDate ref="formItem" :required="item.required" :options="item.options" :identifier="item.key" :label="item.label" :value="item.value" @select="onSelectDate" v-if="item.ui == 'mx-date'" />
      <MXAttachment :identifier="item.key" :label="item.label" :value="item.value" @select="onSelectFile" v-if="item.ui == 'mx-attachment'" />
      <MXLocation :disabled="item.disabled" :identifier="item.key" :label="item.label" :value="item.value" v-if="item.ui == 'mx-location'" @onGetMapLocation="onGetMapLocation" />
    </el-form-item>
  </div>
</template>
<script>
import MXText from '@/pages/components/mx-ui-components/mx-text'
import MXSelect from '@/pages/components/mx-ui-components/mx-select'
import MXSelectMultiple from '@/pages/components/mx-ui-components/mx-select-multiple'
import MXTextArea from '@/pages/components/mx-ui-components/mx-textarea'
import MXInput from '@/pages/components/mx-ui-components/mx-input'
import MXRegion from '@/pages/components/mx-ui-components/mx-region'
import MXDate from '@/pages/components/mx-ui-components/mx-date'
import MXAttachment from '@/pages/components/mx-ui-components/mx-attachment'
import MXLocation from '@/pages/components/mx-ui-components/mx-location'

export default {
  name: 'MXFormItemParser',
  components: {
    MXText,
    MXSelect,
    MXSelectMultiple,
    MXInput,
    MXTextArea,
    MXRegion,
    MXDate,
    MXAttachment,
    MXLocation
  },
  props: ['item'],
  data() {
    return {

    }
  },
  created(){
    // console.log(item,'item');
  },
  methods: {
    reloadApiByParams(api, params) {
      if (this.$refs.selectItem) {
        let newApi = this.generateApi(api, params)
        this.$refs.selectItem.reloadDataByApi(newApi)
      }
    },
    // reloadFormItem (data) {
    //   if (this.$refs.formItem) {
    //   }
    // },
    onInput(e) {
      this.$emit('onInput', e)
    },
    onSelect(e) {
      this.$emit('onSelect', e)
    },
     onSelectMultiple(e) {
      this.$emit('onSelectMultiple', e)
    },
    onSelectRegion(e) {
      this.$emit('onSelectRegion', e)
    },
    onSelectDate(e) {
      this.$emit('onSelectDate', e)
    },
    onSelectFile(e) {
      this.$emit('selectFile', e)
      // 修改问参数
    },
    onGetMapLocation(e) {
      this.$emit('onGetMapLocation', e)
    },
    generateApi(target, params) {
      // let api = target + '?' + this.jsontoQuery(params)
      // return api
      let url = target.split('?').length ? target.split('?')[0] : target
      let queryParams = this.queryURLParams(target)
      let sub = this.jsontoQuery(Object.assign(params, queryParams))
      if (sub.length) url = url + "?" + sub
      return url
    },
    queryURLParams(url) {
      let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
      let parames = {}; // 定义参数对象
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
      });
      return parames;
    },
    jsontoQuery(json) {
      let str = "";
      let query = "";
      for (let i in json) { // i是对象的键值
        str += i + "=" + json[i] + "&" // json[i][j]是属性值
      }
      query = str.substring(0, str.length - 1);
      return query;
    },
  }

}

</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    margin-bottom: 0px;
  }
}

</style>
